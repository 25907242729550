import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function IsLoggedOutWrapper({ children }) {
    // ensure that when logged in, certain pages are inaccessible [/login, /signup/*]
    const router = useRouter();
    const { data, status } = useSession();

    useEffect(() => {
        let path = router.asPath;

        if (
            (path.startsWith("/login") ||
                path.startsWith("/signup") ||
                path === "/") &&
            status === "authenticated" &&
            data?.user
        ) {
            router.push("/home");
        }
    }, [router.asPath, data]);

    if (status !== "unauthenticated") return null;

    return <>{children}</>;
}
